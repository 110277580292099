<template>
  <a-modal v-model="visible" @cancel="handleClose" width="700px" title="添加品牌、车系、标签">
    <div class="tag-line">
      <div class="tag-title"><span style="color: red">*</span>标签：</div>
      <div class="tag-line">
        <CascaderMenu
          ref="cascaderMenu"
          :option="item"
          v-for="(item, index) in tagTree"
          :key="index"
          @change="(props) => handleTagChange(props, index)"
        />
      </div>
    </div>
    <div class="bottom-line">
      <div style="width: 45px px; flex-shrink: 0">已选：</div>
      <div class="tag-list">
        <div class="tags" v-for="value in tagList" :key="value.id">
          <span>{{ value.parentText }}</span
          ><span style="color: #1890ff">{{ value.text }}</span>
          <span>
            <a-icon type="close" style="margin-left: 5px; cursor: pointer" @click="handleTagDel(value)" />
          </span>
        </div>
      </div>
    </div>
    <a-form-model ref="form" :model="form" v-bind="{ labelCol: { span: 3 }, wrapperCol: { span: 20 } }">
      <a-row style="margin-top: 10px">
        <a-col :span="11">
          <a-form-model-item label="品牌">
            <a-select
              mode="multiple"
              v-model="form.brand"
              placeholder="请选择"
              show-search
              option-filter-prop="children"
              @change="handleBrandChange"
              :maxTagCount="2"
              allowClear
            >
              <a-select-option v-for="item in brandList" :key="`${item.id},${item.principalName}`">{{
                item.principalName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="13">
          <a-form-model-item label="车系">
            <a-select
              mode="multiple"
              v-model="form.type"
              placeholder="请选择"
              show-search
              option-filter-prop="children"
              :maxTagCount="2"
              :loading="typeLoading"
              allowClear
            >
              <a-select-option v-for="item in typeList" :key="`${item.id},${item.name}`">{{
                item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <template slot="footer">
      <a-button @click="handleClose">取消</a-button>
      <a-button type="primary" :loading="operateLoading" @click="saveImageLibrary">确认上传</a-button>
    </template>
  </a-modal>
</template>

<script>
import CascaderMenu from '@/components/CascaderMenu.vue';
import api from '@/api/xhsAgencyApi';

export default {
  components: {
    CascaderMenu,
  },
  data() {
    return {
      visible: false,
      tagTree: [],
      tagList: [],
      brandList: [],
      typeList: [],
      imageInfoList: [],
      form: {
        brand: undefined,
        type: undefined,
      },
      typeLoading: false,
      operateLoading: false,
    };
  },
  methods: {
    openModal(data, principalAndCarSeries = {}) {
      Object.assign(this.$data, this.$options.data());
      this.imageInfoList = data;
      this.visible = true;
      this.getTagList();
      this.getBrandList();
      if (Object.keys(principalAndCarSeries).length) {
        principalAndCarSeries.brand && this.handleBrandChange([principalAndCarSeries.brand]);
        this.form.brand = [principalAndCarSeries.brand];
        this.form.type = [principalAndCarSeries.type];
      }
    },
    handleClose() {
      this.visible = false;
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    // 获取标签
    initTagValue(data) {
      if (data.children && data.children.length > 0) {
        data.children = data.children.map(this.initTagValue);
      }
      return {
        ...data,
        name: data.tagName,
        value: data.tagPath,
        key: data.id,
      };
    },
    async getTagList() {
      await this.handleRequest('getTagTree', (data) => {
        this.tagTree = data.map(this.initTagValue);
      });
    },
    // 获取品牌
    async getBrandList() {
      await this.handleRequest('getBrandList', (data) => {
        this.brandList = data;
      });
    },
    // 品牌切换
    async handleBrandChange(id) {
      if (id.length) {
        const idList = id.map((item) => item.split(',')[0]);
        const principalIds =
          idList.reduce((prev, item) => {
            prev += `,${item}`;
            return prev;
          }) || '';
        this.typeLoading = true;
        this.typeList = [];
        this.handleRequest('getNewTypeList', (data) => (this.typeList = data), { principalIds }).finally(
          () => (this.typeLoading = false)
        );
      } else {
        this.typeList = [];
      }
    },
    handleTagChange(value, compIndex) {
      if (value.isAdd) {
        const treeData = this.tagTree[compIndex];
        const parentText = `${treeData.name} > ${value.parent.name} > `;
        const text = value.self.name;
        this.tagList.push({
          id: value.self.id,
          parentText,
          text,
          tagPath: value.self.tagPath,
          compIndex,
        });
      } else {
        const index = this.tagList.indexOf((ele) => ele.id === value.self.id);
        this.tagList.splice(index, 1);
      }
    },
    //保存图片库
    saveImageLibrary() {
      this.operateLoading = true;
      const imageInfoList = this.imageInfoList;

      // 处理标签字段
      let tagList = [];
      if (this.tagList?.length) {
        tagList = this.tagList.map((val) => ({
          tagId: val.id,
          tagName: val.text,
        }));
      } else {
        tagList = [];
      }
      if (tagList.length === 0) {
        this.$message.error('请至少选择一个标签');
        this.operateLoading = false;
        return;
      }

      // 处理品牌字段
      let principalInfoList = [];
      if (this.form.brand?.length) {
        console.log('this.form.brand ->', this.form.brand);
        principalInfoList = this.form.brand.map((val) => {
          const parm = val.split(',');
          return {
            principalId: parm[0],
            principalName: parm[1],
          };
        });
      } else {
        principalInfoList = [];
      }

      // 处理车系字段
      let vehicleSeriesInfoList = [];
      if (this.form.type?.length) {
        vehicleSeriesInfoList = this.form.type.map((val) => {
          const parm = val.split(',');
          return {
            vehicleSeriesId: parm[0],
            vehicleSeriesName: parm[1],
          };
        });
      } else {
        vehicleSeriesInfoList = [];
      }

      let params = {
        appId: 'bfz',
        filePath: '/bfz/xhs/ai/',
        ossFilePath: '/bfz/xhs/ai/',
        imageInfoList,
        principalInfoList,
        vehicleSeriesInfoList,
        tagList,
      };
      api
        .saveImageLibrary(params)
        .then((res) => {
          this.operateLoading = false;
          this.visible = false;
          if (res.code == 200) {
            this.$message.success('存入成品库成功');
          } else {
            this.$message.error(`存入成品库失败，${res.message}`);
          }
        })
        .finally(() => (this.operateLoading = false));
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.tag-line {
  display: flex;

  .tag-title {
    padding-top: 5px;
    width: 50px;
    color: #262626;
  }

  .tag-body {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    color: #2d2d2d;
    align-items: center;

    .tags {
      padding: 5px 10px;
      margin: 0 10px 10px;
      border-radius: 6px;
      cursor: pointer;
    }

    .active {
      background-color: #1890ff;
      color: #fff;
    }

    .tag-add {
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      .tag-input {
        width: 120px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .tag-btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.bottom-line {
  display: flex;
  margin-top: 10px;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;

  .tags {
    margin: 0 5px 5px 0;
    padding: 0 5px;
    border: 1px solid #1890ff;
    border-radius: 3px;
    color: #80c2ff;
    user-select: none;
  }
}
</style>
