<template>
  <div class="">
    <template v-if="step == 'list'">
      <a-button style="margin-bottom: 10px" @click="handleBackMissionList">返回</a-button>
      <a-table
        :loading="tableLoading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record, index) => index"
        :pagination="false"
      >
        <div slot="examineStatus" slot-scope="text">
          <div style="color: #d9001b">未审核：{{ text.pendingCount || 0 }}</div>
          <div>已审核：{{ text.passedCount + text.failedCount || 0 }}</div>
        </div>
        <div slot="operation" slot-scope="text">
          <a-button type="link" @click="handleExamine(text)">{{ text.pendingCount ? '审核' : '查看' }}</a-button>
        </div>
      </a-table>
    </template>

    <template v-if="step == 'detail'">
      <div class="examine-detail">
        <div class="detail-left">
          <div class="detail-container">
            <div class="detail-title">基本信息</div>
            <div class="detail-content">
              <span class="title">成员名称：</span><span class="content">{{ taskInfo.memberName || '-' }}</span>
            </div>
            <div class="detail-content">
              <span class="title">任务类型：</span><span class="content">{{ taskInfo.taskTypeDesc || '-' }}</span>
            </div>
            <div class="detail-content">
              <span class="title">任务名称：</span><span class="content">{{ taskInfo.taskName || '-' }}</span>
            </div>
            <div class="detail-content">
              <span class="title">任务描述：</span><span class="content">{{ taskInfo.description || '-' }}</span>
            </div>
            <div class="detail-content">
              <span class="title">品牌：</span><span class="content">{{ taskInfo.principalName || '-' }}</span>
            </div>
            <div class="detail-content">
              <span class="title">车系：</span><span class="content">{{ taskInfo.carSeriesName || '-' }}</span>
            </div>
            <div class="detail-title" style="margin-top: 30px">审核结果</div>
            <template v-if="showResult">
              <div class="detail-content">
                <span class="title">拍摄图片数：</span><span class="content">{{ taskInfo.totalCount || 0 }}</span>
              </div>
              <div class="detail-content">
                <span class="title">合格图片数：</span><span class="content">{{ taskInfo.passedCount || 0 }}</span>
              </div>
              <div class="detail-content">
                <span class="title">合格率：</span><span class="content">{{ taskInfo.passedRate || 0 }}%</span>
              </div>
            </template>
            <template v-else>
              <div style="color: #b0b0b0">
                <a-icon theme="filled" type="info-circle" style="margin: 0 5px" />请先审核全部图片后查看审核结果
              </div></template
            >
          </div>
        </div>
        <div class="detail-right">
          <a-spin :spinning="detailLoading">
            <div class="item-list">
              <a-checkbox-group class="pic-list" v-model="selectedPicList">
                <div class="img-wrapper" v-for="(item, index) in materialList" :key="index">
                  <img
                    :src="item.materialType === 'VIDEOS' ? item.coverUrl : item.materialUrl"
                    referrerpolicy="no-referrer"
                    @click.stop="checkPic(item, index)"
                  />
                  <div class="play-mask" v-if="item.materialType === 'VIDEOS'">
                    <a-icon
                      style="font-size: 80px; margin-bottom: 20px; cursor: pointer"
                      type="play-circle"
                      @click.stop="downloadVideo(item.materialUrl)"
                    />
                    <!-- <a-icon
                      style="position: absolute; top: 5px; right: 5px; font-size: 26px; cursor: pointer"
                      type="download"
                      @click.stop="downloadVideo(item.materialUrl)"
                    /> -->
                  </div>
                  <template v-if="!item.loading">
                    <a-checkbox v-show="item.materialType === 'PHOTOS'" class="check-box" :value="index" @click.stop="">
                    </a-checkbox>
                    <div class="operate-line" v-if="item.auditStatus === 'PENDING'">
                      <div class="operate-item" @click="handlePass(item)">
                        <a-icon theme="filled" type="check-circle" style="margin-right: 3px" />设为合格
                      </div>
                      <div class="operate-item" style="border-left: 1px solid #fff">
                        <a-popover
                          v-model="item.showPopover"
                          title=""
                          trigger="click"
                          placement="bottom"
                          @visibleChange="handlePopChange"
                        >
                          <div slot="content" class="operate-box">
                            <div style="font-weight: bolder; margin-bottom: 5px">不合格原因</div>
                            <a-radio-group v-model="editData.reason">
                              <a-radio class="radio-option" value="模糊/重影"> 模糊/重影</a-radio>
                              <a-radio class="radio-option" value="图片未突出重点配置">图片未突出重点配置 </a-radio>
                              <a-radio class="radio-option" value="干扰信息太多"> 干扰信息太多 </a-radio>
                              <a-radio class="radio-option" value="灯光太暗/太亮"> 灯光太暗/太亮 </a-radio>
                              <a-radio class="radio-option" :value="5"> 其他 </a-radio>
                              <a-input
                                v-if="editData.reason === 5"
                                style="width: 100%; margin: 5px 0"
                                placeholder="请输入其他原因"
                                v-model.trim="editData.otherReason"
                              />
                            </a-radio-group>
                            <a-space>
                              <a-button size="small" @click="handleCancel(item)">取消</a-button>
                              <a-button
                                size="small"
                                :disabled="!editData.reason || (editData.reason === 5 && !editData.otherReason)"
                                @click="handleConfirm(item)"
                                type="primary"
                                >确认</a-button
                              >
                            </a-space>
                          </div>
                          <div><a-icon theme="filled" type="info-circle" style="margin-right: 3px" />设为不合格</div>
                        </a-popover>
                      </div>
                    </div>
                    <div v-else :class="['operate-line', item.auditStatus === 'PASSED' ? 'success-line' : 'fail-line']">
                      <span>
                        <template v-if="item.auditStatus === 'FAILED'">
                          <a-tooltip :title="item.reason">
                            <a-icon theme="filled" type="info-circle" style="margin-right: 5px" />不合格
                          </a-tooltip>
                        </template>
                        <template v-if="item.auditStatus === 'PASSED'">
                          <a-icon theme="filled" type="check-circle" style="margin-right: 5px" />合格
                        </template>
                      </span>
                      <div>
                        <a-popover
                          v-model="item.showEditPopover"
                          title=""
                          trigger="click"
                          placement="bottom"
                          @visibleChange="handlePopChange"
                        >
                          <div slot="content" class="operate-box">
                            <a-radio-group v-model="editData.reason">
                              <div style="font-weight: bolder; margin-bottom: 5px">合格</div>
                              <a-radio class="radio-option" :value="1"> 合格</a-radio>
                              <div style="font-weight: bolder; margin-bottom: 5px">不合格</div>
                              <a-radio class="radio-option" value="模糊/重影"> 模糊/重影</a-radio>
                              <a-radio class="radio-option" value="图片未突出重点配置"> 图片未突出重点配置 </a-radio>
                              <a-radio class="radio-option" value="干扰信息太多"> 干扰信息太多 </a-radio>
                              <a-radio class="radio-option" value="灯光太暗/太亮"> 灯光太暗/太亮 </a-radio>
                              <a-radio class="radio-option" :value="5"> 其他 </a-radio>
                              <a-input
                                v-if="editData.reason === 5"
                                style="width: 100%; margin: 5px 0"
                                placeholder="请输入其他原因"
                                v-model.trim="editData.otherReason"
                              />
                            </a-radio-group>
                            <a-space>
                              <a-button size="small" @click="handleCancel(item)">取消</a-button>
                              <a-button
                                size="small"
                                :disabled="!editData.reason || (editData.reason === 5 && !editData.otherReason)"
                                @click="handleConfirm(item)"
                                type="primary"
                                >确认</a-button
                              >
                            </a-space>
                          </div>
                          <span class="modify">修改</span>
                        </a-popover>
                      </div>
                    </div>
                  </template>
                  <div class="loading-mask" v-if="item.loading"><a-icon type="loading" /></div>
                </div>
              </a-checkbox-group>
            </div>
          </a-spin>
          <div class="btn-list">
            <a-button @click="handleExamineBack">返回</a-button>
            <div>
              <a-button style="margin-right: 10px" :disabled="!selectedPicList.length" @click="saveImageLib"
                >存入图片库{{ selectedPicList.length ? `(${selectedPicList.length})` : '' }}</a-button
              >
              <a-button :disabled="!selectedPicList.length" @click="batchDownload" type="primary"
                >下载到本地{{ selectedPicList.length ? `(${selectedPicList.length})` : '' }}</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </template>

    <SaveImageLibModal ref="SaveImageLibModal" />

    <PreviewModal :visible="preview_visible" :preview-src="preview_src" controlslist="" @cancel="handlePreviewCancel" />
  </div>
</template>

<script>
import { examineColumns as columns } from './data';
import { Tool } from '@/utils/index.js';
import SaveImageLibModal from '@/components/xhsAgencyTool/SaveImageLibModal.vue';
import PreviewModal from '@/components/PreviewModalVideo/PreviewModalVideo';
import api from '@/api/xhsAgencyApi';
import debounce from 'lodash/debounce';

export default {
  name: 'missionExamine',
  props: {},
  components: { SaveImageLibModal, PreviewModal },
  data() {
    return {
      columns,
      tableLoading: false,
      dataList: [],
      step: 'list', // 'detail'
      selectedPicList: [],
      materialList: [],
      editData: { reason: undefined, otherReason: undefined },
      taskId: undefined,
      taskInfo: {},
      detailLoading: false,
      preview_visible: undefined,
      preview_src: undefined,
    };
  },
  computed: {
    showResult() {
      return this.taskInfo.pendingCount ? false : true;
    },
  },
  watch: {},
  created() {
    this.taskId = this.$route.query.id;
    this.taskId && this.getList();
  },
  mounted() {},
  methods: {
    async getList() {
      this.tableLoading = true;
      const params = {
        xhsTaskId: this.taskId,
      };
      const { code, data, message } = await api.getTaskReviewList(params);
      if (code === 200) {
        this.dataList = data;
      } else {
        this.$message.error(message);
      }
      this.tableLoading = false;
    },
    handleExamine(data) {
      this.step = 'detail';
      const { xhsTaskId, memberId, memberName, passedRate, passedCount, totalCount, pendingCount } = data;
      this.taskInfo = { memberName, passedRate, passedCount, totalCount, pendingCount, memberId };
      this.getExamineDetail(xhsTaskId);
      this.getExamineList(xhsTaskId, memberId);
    },
    async getExamineList(xhsTaskId, memberId) {
      this.detailLoading = true;
      const { code, data, message } = await api
        .getTaskReviewDetailList({ xhsTaskId, memberId })
        .finally(() => (this.detailLoading = false));
      if (code === 200) {
        this.materialList = data.map((item) => {
          item.coverUrl =
            item.materialType === 'VIDEOS'
              ? `${item.materialUrl}?x-oss-process=video/snapshot,t_1000`
              : item.materialUrl;
          item.showPopover = false;
          item.showEditPopover = false;
          item.loading = false;
          return item;
        });
        this.checkResultStatus();
      } else {
        this.$message.error(message);
      }
    },
    async getExamineDetail(xhsTaskId) {
      const { data, message, code } = await api.getTaskDetail(xhsTaskId);
      if (code === 200) {
        this.taskInfo = { ...this.taskInfo, ...data };
      } else {
        this.$message.error(message);
        this.taskInfo = {};
      }
    },
    handleExamineBack() {
      this.step = 'list';
      this.selectedPicList = [];
      this.detailLoading = false;
      this.taskInfo = {};
      this.materialList = [];
      this.getList();
    },
    handlePass(item) {
      this.handleUpdateStatus(item, { id: item.id, auditStatus: 'PASSED' });
    },
    handleConfirm(item) {
      const params = {
        id: item.id,
        auditStatus: this.editData.reason == 1 ? 'PASSED' : 'FAILED',
        reason:
          this.editData.reason == 1
            ? undefined
            : this.editData.reason == 5
            ? this.editData.otherReason
            : this.editData.reason,
      };
      this.handleUpdateStatus(item, params);
    },
    async handleUpdateStatus(item, params) {
      item.loading = true;
      const { code, message } = await api.handleTaskReview(params).finally(() => (item.loading = false));
      if (code === 200) {
        this.$message.success('操作成功');
        item.auditStatus = params.auditStatus;
        item.reason = params.reason;
        this.handleCancel(item);
        this.checkResultStatus();
      } else {
        this.$message.error(message);
      }
    },
    async checkResultStatus() {
      const unDone = this.materialList.some((item) => item.auditStatus === 'PENDING');
      if (!unDone) {
        const params = {
          xhsTaskId: this.taskId,
          memberId: this.taskInfo.memberId,
        };
        const { code, data, message } = await api.getTaskReviewList(params);
        if (code === 200) {
          const { passedRate, passedCount, totalCount, pendingCount } = data[0];
          Object.assign(this.taskInfo, { passedRate, passedCount, totalCount, pendingCount });
        } else {
          this.$message.error(message);
        }
      }
    },
    handleCancel(item) {
      item.showPopover = false;
      item.showEditPopover = false;
      this.editData = this.$options.data().editData;
    },
    checkPic(data) {
      this.preview_visible = true;
      this.preview_src = data.materialUrl;
    },
    handlePreviewCancel() {
      this.preview_src = '';
      this.preview_visible = false;
    },
    downloadVideo: debounce(function (videoUrl) {
      window.open(videoUrl);
    }, 500),
    batchDownload() {
      this.selectedPicList.sort();
      this.selectedPicList.forEach((index) => {
        let suffix = 'jpg';
        let url = this.materialList[index].materialUrl;
        url = url.replace(process.env.VUE_APP_PIC_FHL_OSS_DOMAIN, process.env.VUE_APP_PIC_FHL_OSS_CDN_DOMAIN);
        if (url.includes('/jpg/')) {
          suffix = 'jpg';
        } else if (url.includes('/png/')) {
          suffix = 'png';
        } else if (url.includes('/jpeg/')) {
          suffix = 'jpeg';
        }
        const title = Tool.uuid();
        const name = `${title}.${suffix}`;
        this.downloadImg(url, name, suffix);
      });
    },
    downloadImg(imgSrc, name, suffix) {
      //下载图片地址和图片名
      let image = new Image();
      image.setAttribute('referrerpolicy', 'no-referrer');
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous');
      image.onload = function () {
        let canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL(`image/${suffix}`); //得到图片的base64编码数据'
        let a = document.createElement('a'); // 生成一个a元素
        let event = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }); // 创建一个单击事件
        a.download = name || 'photo'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgSrc.replace(process.env.VUE_APP_PIC_FHL_OSS_DOMAIN, process.env.VUE_APP_PIC_FHL_OSS_CDN_DOMAIN);
    },
    saveImageLib() {
      this.selectedPicList.sort();
      const imageInfoList = this.selectedPicList.map((index) => {
        let suffix = 'jpg';
        let url = this.materialList[index].materialUrl;
        url = url.replace(process.env.VUE_APP_PIC_FHL_OSS_DOMAIN, process.env.VUE_APP_PIC_FHL_OSS_CDN_DOMAIN);
        if (url.includes('/jpg/')) {
          suffix = 'jpg';
        } else if (url.includes('/png/')) {
          suffix = 'png';
        }
        const title = Tool.uuid();
        const name = `${title}.${suffix}`;
        return {
          name,
          title,
          url,
        };
      });
      const principalAndCarSeries = {
        brand: `${this.taskInfo.principalId},${this.taskInfo.principalName}`,
        type: `${this.taskInfo.carSeriesId},${this.taskInfo.carSeriesName}`,
      };
      this.$refs.SaveImageLibModal.openModal(imageInfoList, principalAndCarSeries);
    },
    handleBackMissionList() {
      this.$router.replace({ name: 'missionList' });
    },
    handlePopChange() {
      this.editData = this.$options.data().editData;
    },
  },
};
</script>

<style lang="less" scoped>
.examine-detail {
  display: flex;

  .detail-left {
    flex-shrink: 0;
    min-height: 600px;
    border: 1px solid #d7d7d7;
    border-right: 0;

    .detail-container {
      width: 300px;
      padding: 10px;

      .detail-title {
        font-weight: bolder;
        margin-bottom: 10px;
      }

      .detail-content {
        display: flex;
        margin-bottom: 5px;
        padding: 0 10px;

        .title {
          width: 85px;
          flex-shrink: 0;
          color: #868686;
        }

        .content {
          font-weight: bold;
        }
      }
    }
  }

  .detail-right {
    flex: 1;
    padding: 15px;
    border: 1px solid #d7d7d7;

    .item-list {
      min-height: 500px;
    }

    .btn-list {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      text-align: center;
    }
  }

  .pic-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 24px;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;

    .img-wrapper {
      width: 196px;
      height: 200px;
      border: 1px solid #d8d8d8;
      position: relative;
      background-color: #000;
      text-align: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }

    .check-box {
      position: absolute;
      right: 3px;
      top: 3px;

      /deep/ .ant-checkbox-inner {
        width: 25px;
        height: 25px;

        &:after {
          left: 33%;
        }
      }
    }

    .operate-line {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      width: 100%;
      user-select: none;
      cursor: pointer;
      height: 30px;
      line-height: 30px;
      font-size: 13px;

      .operate-item {
        flex: 1;
        text-align: center;
        color: #545856;
        background-color: #ccccccde;
        &:hover {
          color: #fff;
          background-color: #2382dd;
        }
      }

      &.success-line {
        justify-content: space-between;
        padding: 0 10px;
        color: #fff;
        background-color: #28a36ad6;
      }

      &.fail-line {
        justify-content: space-between;
        padding: 0 10px;
        color: #fff;
        background-color: rgba(255, 0, 0, 0.514);
      }

      .modify {
        &:hover {
          color: #ffffff91;
        }
      }
    }
  }
}

.operate-box {
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: #fff;
}

.radio-option {
  display: block;
  height: 30px;
  line-height: 30px;
}

.loading-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
}

.play-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #00000082;
}
</style>
